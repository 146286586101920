import React from 'react';
// eslint-disable-next-line
import background from './background.jpg';
import './App.css';
import { Container } from 'react-bootstrap';
import FeedbackArea from './components/FeedbackArea';
import Questions from './components/Questions';

function App() {
  return (
    <>
      <Container fluid className="App">
        <h1 className='font-whisper header'>Wir sagen am 07. September 2024: Ja!</h1>
        <a href='#content-page' className='continue pulse'>{'>>'}</a>
      </Container>
      <FeedbackArea />
      <Questions />
    </>
  );
}

export default App;
