import React from 'react';

interface HeadingProps {
    title: string;
}

export default function Heading({ title }: Readonly<HeadingProps>) {
    return (
        <h3>{title}</h3>
    );
}