import React from 'react';
import './Questions.css';
import { Accordion, Container, Image } from 'react-bootstrap';
import Heading from './Heading';

function Questions() {
  return (
    <Container fluid className='content' id='content-page'>
        <Container>
            <Heading title='Trauung' />
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Wann und wo findet die Hochzeit statt?</Accordion.Header>
                    <Accordion.Body>
                        Wir heiraten am 07. September 2024 auf der{' '}
                        <a href="https://maps.app.goo.gl/gjrPVfQEdAMLGpZF8" title='Seebrücke Sellin' rel="noreferrer" target="_blank">Seebrücke Sellin</a>.
                        Die Trauung findet um 13 Uhr statt. Bitte seit 12.30 Uhr vor Ort, sodass wir pünktlich beginnen können und nehmt eure Plätze bis 12.40 Uhr ein.
                        Ihr findet euren Platz anhand von Sitzkarten auf den Stühlen.
                        Bitte denkt daran eure Handys lautlos zu schalten und verzichtet auf eigene Fotos. Wir haben eine professionelle
                        Fotografin dabei und teilen Fotos gerne mit euch.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Wer ist bei der Trauung dabei?</Accordion.Header>
                    <Accordion.Body>
                        Bei der Trauung werden die Familien und der engste Freundeskreis dabei sein.
                        Diese haben von uns eine extra Einladung erhalten. Bitte sei uns nicht 
                        böse, wenn du nicht dabei sein kannst - ab dem Nachmittag feiern wir alle gemeinsam.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Wann sollten wir los fahren?</Accordion.Header>
                    <Accordion.Body>
                        Im September ist auf Rügen noch Hochsaison. Die reine Fahrtzeit 
                        beträgt 30 Minuten. Allerdings ist der Verkehr besonders durch die Ortschaften langsamer als 
                        gewöhnlich. Daher empfehlen wir eine Abfahrt um spätestens 11.30 Uhr.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header> Wo können wir parken?</Accordion.Header>
                    <Accordion.Body>
                        Die Seebrücke hat keinen eigenen Parkplatz. Wir empfehlen dir den 
                        kostenpflichtigen Parkplatz an der Kurverwaltung Sellin. 
                        Von dort aus ist es ein Fußweg von ca. 600m. Die Parkplatzkapazitäten sind allerdings begrenzt,
                        daher empfehlen wir Fahrgemeinschaften zu bilden. 
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br />
            <Heading title='Übernachtungsmöglichkeiten' />
            <Accordion>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Auf dem Gut Krimvitz</Accordion.Header>
                    <Accordion.Body>
                        Auf dem Gut Krimvitz gibt es ein paar Zimmer und zwei Ferienwohnungen, welche du über uns mieten 
                        kannst. Es gibt nicht genügend Zimmer, damit alle an der Location schlafen können. Daher hab 
                        Verständnis, dass wir in der Zimmerverteilung zuerst den Familien mit Kindern die Möglichkeit 
                        geben möchten. Andernfalls wäre für sie die Feier schon viel zu früh zu Ende. Solltest du bei deiner 
                        Zusage angegeben haben, dass du Interesse an einem Zimmer auf dem Gut hast, dann werden 
                        wir uns zeitnah nach Ablauf der Rückmeldefrist bei dir melden und dich informieren, ob wir 
                        dir ein Zimmer geben können oder nicht.<br /><br />
                        <strong>Hier die Eckdaten für diese Zimmer:</strong>
                        <ul>
                            <li>Kosten für ein Zimmer 200 Euro</li>
                            <li>
                                Die Anmeldung erfolgt über das Formular zur Hochzeit.
                                Bitte kontaktiert nicht selbst das Gutshaus.
                            </li>
                            <li>Inkl. Bettwäsche, Handtücher, Parkplatz</li>
                            <li>Inkl. Frühstück am Samstag und Sonntag ab 9 Uhr</li>
                            <li>
                                Kinderbetten für die Kleinsten sind nicht vorhanden und müssen selbst mitgebracht 
                                werden. Eine Aufbettung ist ggfs. möglich.
                            </li>
                            <li>Mit allen Übernachtungsgästen wird am Freitag ein Welcome-BBQ stattfinden</li>
                            <li>Check-In ab 15 Uhr, Check-Out bis 11 Uhr</li>
                            <li>
                                Haustiere sind in den Zimmern nicht gestattet. Als Ausnahme hat die Eigentümerin 
                                unseren Hund genehmigt. In den beiden Ferienwohnungen sind Hunde ebenfalls erlaubt.
                            </li>
                            <li>
                                Fußläufig ist kein Supermarkt in der Nähe. Denk daher bitte an Wasser etc. für die 
                                Zeiten außerhalb der Feier.
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Andere Übernachtungsmöglichkeiten</Accordion.Header>
                    <Accordion.Body>
                        Du hast auf Rügen die freie Auswahl an Übernachtungsmöglichkeiten. Hier nur einige mögliche 
                        Empfehlungen von uns:
                        <ul>
                            <li>
                                <strong>Heu-Ferienhof Altkamp:</strong> Mehrere Ferienwohnungen, Mindestaufenthalt von
                                3 Nächten (bspw. Donnerstag bis Sonntag), teilweise Haustiere erlaubt, Fahrzeit zur
                                Location: 11 Minuten
                            </li>
                            <li>
                                <strong>Hotel am Wiesengrund:</strong> 2-, 3-, oder 4-Bett Zimmer,
                                inkl. Frühstück, Fahrzeit zur Location: 9 Minuten
                            </li>
                            <li>
                                <strong>Ferienhaus Inselperle:</strong> 2-4 Personen, Haustiere erlaubt,
                                Fahrzeit zur Location: 16 Minuten
                            </li>
                            <li>
                                <strong>Wreecher Hof:</strong> Einzel- und Doppelhzimmer, Haustiere erlaubt,
                                eigenes Schwimmbad, Fahrzeit zur Location: 15 Minuten
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br />
            <Heading title='Weitere Infos' />
            <Accordion>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Begleitpersonen</Accordion.Header>
                    <Accordion.Body>
                        Prinzipiell bekommt jeder eine Einladung, den wir bei unserer Hochzeit dabeihaben möchten.
                        Entsprechend sind wir nicht auf weitere Besucher vorbereitet. Sollten wir jemanden in der Einladung
                        vergessen haben, schreib uns gerne.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>Dresscode</Accordion.Header>
                    <Accordion.Body>
                        Unsere Feier wird hauptsächlich draußen auf dem Gut stattfinden. Hier gibt es zum 
                        Großteil Wiese, weshalb dünne Absätze eine kleine Herausforderung sein können. Die Farben Weiß 
                        und Creme bleiben an diesem besonderen Tag der Braut vorbehalten. Ansonsten bist du in der 
                        Kleiderwahl ganz frei: Hauptsache du fühlst dich wohl!
                        <Image src={require('../desses.jpg')} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>Geschenke</Accordion.Header>
                    <Accordion.Body>
                        Alle unsere Gäste haben eine lange Anreise und deshalb ist deine Anwesenheit Geschenk genug.
                        Sollten wir dich dennoch nicht davon abhalten können, freuen wir uns über eine Unterstützung
                        für unsere Flitterwochen. Wir haben ein Hochzeitskonto eröffnet und geben dir auf Nachfrage
                        gerne die Kontoverbindung.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>Kinder</Accordion.Header>
                    <Accordion.Body>
                        Da wir selbst Eltern eine kleine Tochter haben, sind Kinder natürlich bei unserer
                        Feier dabei.
                        Das Gut verfügt über eine große Wiese, auf der getobt werden kann. Es sind Hochstühle vorhanden
                        und auf dem Abendbuffet wird es auch einige kinderfreundliche Optionen geben.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header>Reden & Spiele</Accordion.Header>
                    <Accordion.Body>
                        Du fühlst dich zum Redner berufen oder möchtest ein Spiel organisieren? Darüber freuen wir uns!
                        Bitte kontaktiere dafür unsere Trauzeugin Vivien (<a href="tel:+4917624669897" title="Telefonnr. von Vivien">+4917624669897</a>). Sie kann mit dir gemeinsam einplanen, wann es 
                        gut passen würde und ist darüber informiert, was auf dem Gut möglich ist bzw. was durch die 
                        Eigentümerin nicht erlaubt ist. So bleibt es für uns eine Überraschung.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                    <Accordion.Header>Adressen</Accordion.Header>
                    <Accordion.Body>
                        <ul>
                            <li>Ort der Trauung: <a href="https://maps.app.goo.gl/gjrPVfQEdAMLGpZF8" title='Seebrücke Sellin' rel="noreferrer" target="_blank">Seebrücke Sellin</a></li>
                            <li><a href='https://maps.app.goo.gl/376mVeBoXhHb8w1S8' title='Parkplatz an der Kurverwaltung Sellin' rel="noreferrer" target="_blank">Parkplatz an der Kurverwaltung Sellin:</a> August-Bebel-Straße 20A, 18586 Sellin</li>
                            <li>Ort der Feier: <a href="https://maps.app.goo.gl/1g3BL2aPLcyFuCgc6" title='Gutshaus Krimvitz' rel="noreferrer" target="_blank">Gutshaus Krimvitz</a>, Krimvitz 4, 18581 Putbus</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                    <Accordion.Header>Ablaufplan</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Es läuft alles selten nach Plan und an unserem Hochzeitstag wird auch niemand mit einer 
                            Uhr die Zeit stoppen. ;) Trotzdem findest du hier als grobe Orientierung den Ablaufplan.
                        </p>
                        <ul>
                            <li>
                                <strong>16 Uhr: </strong>Sektempfang und Beginn der Feier. Freue dich auf herzhafte Häppchen
                                oder ein Stück von der Hochzeitstorte.
                            </li>
                            <li><strong>19 Uhr: </strong>Ein vielseitiges und leckeres Abendbuffet wartet auf dich.</li>
                            <li><strong>20 Uhr: </strong>Beginn der Party mit Musik und Tanz. Schwing das Tanzbein mit uns.</li>
                            <li><strong>22 Uhr: </strong>Für die Tanzwütigen und Ausgehungerten naht Rettung: der Mitternachtssnack.</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    </Container>
  );
}

export default Questions;
