import React from 'react';
import './FeedbackArea.css';
import { Container } from 'react-bootstrap';

function FeedbackArea() {
  return (
    <Container fluid className='feedback-area' id='content-page'>
        <Container>
            Vielen Dank für die zahlreichen Rückmeldungen. Wir haben uns über jede
            sehr gefreut und fiebern unserer Hochzeit nun noch mehr entgegen.
        </Container>
    </Container>
  );
}

export default FeedbackArea;
